<template>
  <main>
    <div align="center">
      <v-card class="pa-4" color="teal" width="600" v-if="showForm">
        <v-icon color="teal darken-4" size="200" class="no-italics">
          tz-sdi-participant
        </v-icon>
        <v-row justify="space-around" class="text-shadow">
          <v-btn icon href="https://t.me/joinchat/c5tRUL8mXoY5Zjlk">
            <v-icon large color="blue darken-3">
              mdi-telegram
            </v-icon>
          </v-btn>
          <v-btn icon href="https://chat.whatsapp.com/D3ql4OqEaSEDaCCZim3ZLI">
            <v-icon large color="green">
              mdi-whatsapp
            </v-icon>
          </v-btn>
          <v-btn icon href="mailto:inquiry@blackatmosphere.co.za">
            <v-icon large color="yellow darken-2">
              mdi-email
            </v-icon>
          </v-btn>
          <v-btn icon href="sms:+27614432115">
            <v-icon large color="blue darken-2">
              mdi-message-text
            </v-icon>
          </v-btn>
          <v-btn icon href="tel:+27614432115">
            <v-icon large color="green">
              mdi-phone
            </v-icon>
          </v-btn>
        </v-row>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="submit">
            <validation-provider
              v-slot="{ errors }"
              name="given_name"
              rules="required|max:15"
            >
              <v-text-field
                v-model="given_name"
                :counter="15"
                :error-messages="errors"
                color="white--text"
                label="Firstname"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="family_name"
              rules="required|max:15"
            >
              <v-text-field
                v-model="family_name"
                :counter="15"
                :error-messages="errors"
                color="white--text"
                label="Lastname"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="email_address"
              rules="required|email"
            >
              <v-text-field
                v-model="email_address"
                :error-messages="errors"
                color="white--text"
                label="E-mail"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="phone_number"
              :rules="{
                required: true,
                max: 13,
              }"
            >
              <v-text-field
                v-model="phone_number"
                :counter="13"
                :error-messages="errors"
                color="white--text"
                label="Phone Number"
                required
              ></v-text-field>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="interest"
              rules="required"
            >
              <v-select
                v-model="interest"
                :items="interest_items"
                item-color="teal"
                :error-messages="errors"
                class="teal"
                color="white--text"
                background-color="teal"
                label="Interest"
                data-vv-name="interest"
                required
              ></v-select>
            </validation-provider>
            <validation-provider name="message">
              <v-textarea
                v-model="message"
                filled
                auto-grow
                label="Message"
                rows="2"
                row-height="20"
              ></v-textarea
            ></validation-provider>
            <validation-provider v-slot="{ errors }" name="marketing_consent">
              <v-checkbox
                v-model="marketing_consent"
                :error-messages="errors"
                :value="true"
                color="white"
                label="Subscribe to our marketing emails"
                type="checkbox"
              ></v-checkbox>
            </validation-provider>

            <v-btn
              class="mr-4"
              type="submit"
              color="green lighten-1"
              :disabled="invalid"
            >
              submit
            </v-btn>
            <v-btn @click="clear" color="red lighten-1">
              clear
            </v-btn>
          </form>
        </validation-observer>
      </v-card>
      <v-progress-circular
        v-if="loading"
        :size="300"
        color="blue lighten-4"
        indeterminate
      ></v-progress-circular>
      <v-card
        class="pa-4"
        color="green lighten-1"
        width="600"
        v-if="joinSuccess"
      >
        <v-icon color="green darken-4" size="200" class="no-italics">
          tz-dai-agreement-b
        </v-icon>
        <h3>Thank you. Please check your emails for a verification link.</h3>
        <v-btn dark color="green darken-4" @click="returnHome">
          Return Home
        </v-btn>
      </v-card>

      <v-card
        class="pa-4"
        color="orange darken-1"
        width="600"
        v-if="joinConflict"
      >
        <v-icon color="orange darken-4" size="200" class="no-italics">
          tz-owi-deadline
        </v-icon>
        <h3>Looks like you've already joined us from before...</h3>
        <v-btn color="orange lighten-4" @click="returnHome">
          Return Home
        </v-btn>
      </v-card>

      <v-card class="pa-4" color="red lighten-1" width="600" v-if="joinFailure">
        <v-icon color="red darken-4" size="200" class="no-italics">
          tz-esi-wrench-in-hand
        </v-icon>
        <h3>
          There was an error on our side, we will have a look and get back to
          you
        </h3>
        <v-btn color="read lighten-4" @click="returnHome">
          Return Home
        </v-btn>
      </v-card>
    </div>
  </main>
</template>

<style scoped>
.text-shadow {
  text-shadow: 1px 1px 2px black;
}

input,
input:-webkit-autofill {
  background: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #009688 inset !important;
  -webkit-box-shadow: 0 0 0 30px #009688 inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}

.v-list {
  background: #009688;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
</style>

<script>
import axios from "axios";

import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});

export default {
  title: "BA - Join Us",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    given_name: "",
    family_name: "",
    email_address: "",
    phone_number: "",
    interest: null,
    message: "",
    interest_items: ["Services", "Partnership", "Research", "Work"],
    marketing_consent: true,
    loading: false,
    joinSuccess: false,
    joinConflict: false,
    joinFailure: false,
  }),
  computed: {
    showForm() {
      return (
        !this.loading &&
        !this.joinSuccess &&
        !this.joinConflict &&
        !this.joinFailure
      );
    },
  },
  methods: {
    submit() {
      this.$refs.observer.validate();
      this.loading = true;
      const payload = {
        given_name: this.given_name,
        family_name: this.family_name,
        email_address: this.email_address,
        phone_number: this.phone_number,
        interest: this.interest,
        message: this.message,
        marketing_consent:
          this.marketing_consent === true ? this.marketing_consent : false,
      };

      axios
        .post("https://mailer.thabangz.com/api/join/blackatmosphere", payload)
        .then(() => {
          this.loading = false;
          this.joinSuccess = true;
        })
        .catch((error) => {
          this.loading = false;
          if ("response" in error && error.response.status === 409) {
            this.joinConflict = true;
          } else {
            this.joinFailure = true;
          }
        });
    },
    clear() {
      this.given_name = "";
      this.family_name = "";
      this.email_address = "";
      this.phone_number = "";
      this.interest = null;
      this.message = "";
      this.marketing_consent = true;
      this.$refs.observer.reset();
    },
    returnHome() {
      this.$router.push("/");
    },
  },
};
</script>
