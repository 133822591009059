var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('div',{attrs:{"align":"center"}},[(_vm.showForm)?_c('v-card',{staticClass:"pa-4",attrs:{"color":"teal","width":"600"}},[_c('v-icon',{staticClass:"no-italics",attrs:{"color":"teal darken-4","size":"200"}},[_vm._v(" tz-sdi-participant ")]),_c('v-row',{staticClass:"text-shadow",attrs:{"justify":"space-around"}},[_c('v-btn',{attrs:{"icon":"","href":"https://t.me/joinchat/c5tRUL8mXoY5Zjlk"}},[_c('v-icon',{attrs:{"large":"","color":"blue darken-3"}},[_vm._v(" mdi-telegram ")])],1),_c('v-btn',{attrs:{"icon":"","href":"https://chat.whatsapp.com/D3ql4OqEaSEDaCCZim3ZLI"}},[_c('v-icon',{attrs:{"large":"","color":"green"}},[_vm._v(" mdi-whatsapp ")])],1),_c('v-btn',{attrs:{"icon":"","href":"mailto:inquiry@blackatmosphere.co.za"}},[_c('v-icon',{attrs:{"large":"","color":"yellow darken-2"}},[_vm._v(" mdi-email ")])],1),_c('v-btn',{attrs:{"icon":"","href":"sms:+27614432115"}},[_c('v-icon',{attrs:{"large":"","color":"blue darken-2"}},[_vm._v(" mdi-message-text ")])],1),_c('v-btn',{attrs:{"icon":"","href":"tel:+27614432115"}},[_c('v-icon',{attrs:{"large":"","color":"green"}},[_vm._v(" mdi-phone ")])],1)],1),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('validation-provider',{attrs:{"name":"given_name","rules":"required|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":15,"error-messages":errors,"color":"white--text","label":"Firstname","required":""},model:{value:(_vm.given_name),callback:function ($$v) {_vm.given_name=$$v},expression:"given_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"family_name","rules":"required|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":15,"error-messages":errors,"color":"white--text","label":"Lastname","required":""},model:{value:(_vm.family_name),callback:function ($$v) {_vm.family_name=$$v},expression:"family_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email_address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"white--text","label":"E-mail","required":""},model:{value:(_vm.email_address),callback:function ($$v) {_vm.email_address=$$v},expression:"email_address"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"phone_number","rules":{
              required: true,
              max: 13,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":13,"error-messages":errors,"color":"white--text","label":"Phone Number","required":""},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"interest","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{staticClass:"teal",attrs:{"items":_vm.interest_items,"item-color":"teal","error-messages":errors,"color":"white--text","background-color":"teal","label":"Interest","data-vv-name":"interest","required":""},model:{value:(_vm.interest),callback:function ($$v) {_vm.interest=$$v},expression:"interest"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"message"}},[_c('v-textarea',{attrs:{"filled":"","auto-grow":"","label":"Message","rows":"2","row-height":"20"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('validation-provider',{attrs:{"name":"marketing_consent"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"error-messages":errors,"value":true,"color":"white","label":"Subscribe to our marketing emails","type":"checkbox"},model:{value:(_vm.marketing_consent),callback:function ($$v) {_vm.marketing_consent=$$v},expression:"marketing_consent"}})]}}],null,true)}),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","color":"green lighten-1","disabled":invalid}},[_vm._v(" submit ")]),_c('v-btn',{attrs:{"color":"red lighten-1"},on:{"click":_vm.clear}},[_vm._v(" clear ")])],1)]}}],null,false,1438135536)})],1):_vm._e(),(_vm.loading)?_c('v-progress-circular',{attrs:{"size":300,"color":"blue lighten-4","indeterminate":""}}):_vm._e(),(_vm.joinSuccess)?_c('v-card',{staticClass:"pa-4",attrs:{"color":"green lighten-1","width":"600"}},[_c('v-icon',{staticClass:"no-italics",attrs:{"color":"green darken-4","size":"200"}},[_vm._v(" tz-dai-agreement-b ")]),_c('h3',[_vm._v("Thank you. Please check your emails for a verification link.")]),_c('v-btn',{attrs:{"dark":"","color":"green darken-4"},on:{"click":_vm.returnHome}},[_vm._v(" Return Home ")])],1):_vm._e(),(_vm.joinConflict)?_c('v-card',{staticClass:"pa-4",attrs:{"color":"orange darken-1","width":"600"}},[_c('v-icon',{staticClass:"no-italics",attrs:{"color":"orange darken-4","size":"200"}},[_vm._v(" tz-owi-deadline ")]),_c('h3',[_vm._v("Looks like you've already joined us from before...")]),_c('v-btn',{attrs:{"color":"orange lighten-4"},on:{"click":_vm.returnHome}},[_vm._v(" Return Home ")])],1):_vm._e(),(_vm.joinFailure)?_c('v-card',{staticClass:"pa-4",attrs:{"color":"red lighten-1","width":"600"}},[_c('v-icon',{staticClass:"no-italics",attrs:{"color":"red darken-4","size":"200"}},[_vm._v(" tz-esi-wrench-in-hand ")]),_c('h3',[_vm._v(" There was an error on our side, we will have a look and get back to you ")]),_c('v-btn',{attrs:{"color":"read lighten-4"},on:{"click":_vm.returnHome}},[_vm._v(" Return Home ")])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }